import React, { useContext, useEffect } from "react";
import { MainContext } from "../context/MainContext";
import fetchService from "../utils/fetch.service";

const DataMiddleware = ({ children }) => {
    const { userContext, isLoggedContext } = useContext(MainContext);
    const [user, setUser] = userContext;
    const [, setIsLogged] = isLoggedContext;

    useEffect(() => {
        if (!user) {
            fetchService.post("/user").then((resp) => {
                if (resp && resp.data && resp.data.user) {
                    setUser(resp.data.user);
                    setIsLogged(true);
                }
            });
        }
    }, []);

    return (children);
};

export default DataMiddleware;
