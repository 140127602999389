import * as React from "react";
import "./style.css";
import "@elastic/eui/dist/eui_theme_dark.css";

import { EuiProvider } from "@elastic/eui";
import { Route, Routes } from "react-router-dom";
import MainPage from "./pages/Main.page";
import MainLayout from "./layouts/Main.layout";
import MyProfile from "./pages/Profile.page";
import { MainContextProvider } from "./context/MainContext";
import DataMiddleware from "./components/DataMiddleware";
import AuthPage from "./pages/Auth.page";
import AuthMiddleware from "./components/AuthMiddleware";
import VerifyPage from './pages/Verify.page';
import AdminPage from "./pages/Admin.page";

const App = () => (
    <EuiProvider colorMode="dark">
        <MainContextProvider>
            <DataMiddleware>
                <Routes>
                    <Route path="/" element={ <AuthMiddleware><MainLayout/> </AuthMiddleware> }>
                        <Route index element={ <MainPage/> }/>
                        <Route path="profile" element={ <MyProfile/> }/>
                        <Route path="verify" element={ <VerifyPage/> }/>
                        <Route path="admin" element={ <AdminPage/> }/>
                    </Route>
                    <Route path="login" element={ <AuthPage/> }/>
                </Routes>
            </DataMiddleware>
        </MainContextProvider>
    </EuiProvider>
);
export default App;
