import React, { useCallback, useContext, useState } from 'react';
import {
    EuiRadio,
    EuiSelect,
    EuiRange,
    EuiButton,
    EuiFieldText,
    EuiSwitch,
    EuiFormRow,
    EuiCallOut,
    EuiAvatar
} from '@elastic/eui';
import { FormItemHeadingStyled, FormItemStyled, FormStyled, SEX_OPTIONS } from '../components/SearchPanel';
import styled from 'styled-components';
import { MainContext } from '../context/MainContext';
import GoBackToButton from '../components/GoBackTo';
import TelegramLoginButton from '../components/TelegramAuth';
import fetchService from '../utils/fetch.service';
import { Link, useNavigate } from 'react-router-dom';
import DATA from "../const/data";

export const MyProfileStyled = styled.div`

`;

export const ProfileImageWrapperStyled = styled.div`
  .poikk {
    p {
      line-height: 2;
    }
  }
`;
export const ProfileImageStyled = styled.div`
  img {
    max-width: 100px;
    margin: 10px 0;
  }
`;

const MyProfile = () => {
    document.title = `Profile${ DATA.websiteTitle }`;
    const navigate = useNavigate();
    const { userContext, profilesContext, searchedContext } = useContext(MainContext);
    const [user, setUser] = userContext;
    const [, setProfiles] = profilesContext;
    const [, setSearched] = searchedContext;

    const [displayName, setDisplayName] = useState(user.displayName || '');
    const [username] = useState(user.username || '');
    const [gender, setGender] = useState(user.gender || 'man');
    const [orientation, setOrientation] = useState(user.orientation || 'straight');
    const [age, setAge] = useState(user.age || 22);
    const [displayOnSearches, setDisplayOnSearches] = useState(user.displayOnSearch === false ? false : true);

    const isSaveButtonDisabled = () => {
        if (!displayName) return true;
        if (!username) return true;
        if (!gender) return true;
        if (!orientation) return true;
        if (!age) return true;
    };

    const gotoMain = () => {
        navigate('/', { replace: true });
    };

    const onTelegramAuth = useCallback(async (userD) => {
        fetchService.post('/auth', userD).then(res => {
            if (res.data.user) {
                setUser(res.data.user);
            }
        }).catch(err => {
            console.log(err);
        });
    }, []);

    const saveTheUserData = async () => {
        const dataToSend = {
            displayName,
            gender,
            orientation,
            age,
            displayOnSearch: displayOnSearches
        };
        const updatedData = await fetchService.put('/user', dataToSend);
        setUser(updatedData.data.user);
        setSearched(false);
        setProfiles([]);
        gotoMain();
        
    };

    const orientationOptionsFiltered = SEX_OPTIONS.filter((option) => {
        if (option.klo.find(a => a === gender)) return true;
    });

    return (
        <MyProfileStyled>
            <FormStyled>
                <div className="wrapper">
                    <FormItemStyled>
                        <GoBackToButton backTo={ '/' } goToText={ 'Search' }/>
                    </FormItemStyled>
                    <FormItemStyled>
                        <ProfileImageWrapperStyled>
                            <ProfileImageStyled>
                                <EuiAvatar
                                    imageUrl={ user.photo_url }
                                    size="l"
                                    name={ displayName || 'No name' }
                                />
                            </ProfileImageStyled>
                            <div className="poikk">
                                { !user.pic && (
                                    <p>Your profile will look more attractable if you set a pic</p>
                                ) }
                            </div>
                        </ProfileImageWrapperStyled>
                    </FormItemStyled>

                    <FormItemStyled>
                        <EuiFormRow fullWidth label="My Display Name"
                                    helpText="That's how your name is displayed in searches">
                            <EuiFieldText
                                fullWidth
                                value={ displayName }
                                onChange={ (e) => setDisplayName(e.target.value) }
                            />
                        </EuiFormRow>

                    </FormItemStyled>

                    <FormItemStyled>
                        <EuiFormRow label="My @Username" fullWidth
                                    helpText="Your telegram username, that's how people will contact you">
                            <EuiFieldText
                                value={ username }
                                disabled
                                isInvalid={ !username }
                                fullWidth
                            />
                        </EuiFormRow>
                        { !username && (
                            <div className="juiawdw">
                                <EuiCallOut title="You must set username" color="danger" iconType="alert">
                                    <p>Your username is empty, please set username in <b>TELEGRAM app</b> and press
                                        login button again</p>
                                </EuiCallOut>
                                <TelegramLoginButton dataOnauth={ onTelegramAuth } botName="tele_sex_chat_bot"
                                                     buttonSize="medium"/>
                            </div>
                        ) }
                    </FormItemStyled>


                    <FormItemStyled>
                        <FormItemHeadingStyled>I am</FormItemHeadingStyled>
                        <div className="gulchinara">
                            <EuiRadio
                                id={ 'awdaw' }
                                label="Woman"
                                disabled={ user.po }
                                checked={ gender === 'woman' }
                                onChange={ () => {
                                    setGender('woman');
                                }
                                }
                            />
                            <EuiRadio
                                id={ 'awdaw33' }
                                label="Man"
                                disabled={ user.po }
                                checked={ gender === 'man' }
                                onChange={ () => setGender('man') }
                            />
                        </div>
                        { gender === 'woman' &&  !user.po &&(
                            <EuiCallOut title="Verify your gender!" color="success" iconType="user">
                                <p>You can verify your gender, <Link to={ '/verify' }>read more</Link></p>
                            </EuiCallOut>
                        ) }
                    </FormItemStyled>

                    <FormItemStyled className="orientation">
                        <FormItemHeadingStyled>My Orientation</FormItemHeadingStyled>
                        <EuiSelect
                            fullWidth
                            options={ orientationOptionsFiltered }
                            value={ orientation }
                            onChange={ e => setOrientation(e.target.value) }
                        />
                    </FormItemStyled>

                    <FormItemStyled className="age">
                        <FormItemHeadingStyled>My Age</FormItemHeadingStyled>
                        <EuiRange
                            fullWidth
                            min={ 18 }
                            max={ 90 }
                            step={ 1 }
                            value={ age }
                            onChange={ (e) => setAge(e.target.value) }
                            showLabels
                            showRange
                            showValue
                        />
                    </FormItemStyled>

                    <FormItemStyled className="iozf">
                        <EuiSwitch
                            label="Display my self in search results (You have to turn this on to be able to chat with other users)"
                            checked={ displayOnSearches }
                            onChange={ () => setDisplayOnSearches(!displayOnSearches) }
                        />
                    </FormItemStyled>
                    <FormItemStyled>
                        <EuiButton
                            color={ 'success' }
                            isDisabled={ isSaveButtonDisabled() }
                            fill
                            onClick={ saveTheUserData }
                        >
                            Save
                        </EuiButton>
                    </FormItemStyled>
                </div>
            </FormStyled>
        </MyProfileStyled>
    );
};

export default MyProfile;
