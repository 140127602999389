import React from 'react';
import { EuiLoadingChart } from '@elastic/eui';
import styled from 'styled-components';

const WrapperStyled = styled.div`
  margin: 10px auto;
  text-align: center;
`;

const Loader = () => {
    return (
        <WrapperStyled>
            <EuiLoadingChart size="m"/>
        </WrapperStyled>
    );
};

export default Loader;