import React, { useContext, useEffect, useState } from "react";
import fetchService from "../utils/fetch.service";
import { MainContext } from "../context/MainContext";
import styled from "styled-components";


const WrapperStyled = styled.div`
  width: 600px;
  margin: 10px auto 50px;

  @media (max-width: 800px) {
    width: 100%;
    margin: 10px 0;
    padding: 2%;
  }

  h1 {
    font-size: 30px;
  }

  .profile {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 30px;

    h2 {
      font-size: 25px;
    }

    img {
      width: 300px;
    }
  }
`;

const AdminPage = () => {
    const [profiles, setProfiles] = useState([]);

    const { userContext } = useContext(MainContext);
    const [user] = userContext;


    useEffect(() => {
        fetchService.post("/user-all").then(res => {
            setProfiles(res.data);
        }).catch(err => {
            console.log(err);
        });
    }, []);


    if (user.role !== "admin" && user.role !== "moderator") {
        return (<div>AA</div>);
    }

    return (
        <WrapperStyled>
            <h1>List of users</h1>

            { profiles.map((profile, index) => {
                return (
                    <div className="profile" key={ index + "kkawd" + profile._id }>
                        <h2>displayName: { profile.displayName }</h2>
                        <img src={ profile.photo_url } alt=""/>
                        <p>username: { profile.username }</p>
                        <p>gender: { profile.gender }</p>
                        <p>orientation: { profile.orientation }</p>
                        <p>age: { profile.age }</p>
                        <p>displayOnSearch : { toStringUtil(profile.displayOnSearch) }</p>
                        <p>verified: { toStringUtil(profile.po) }</p>
                        <p>enabled:{ toStringUtil(profile.enabled) }</p>
                        <p>role:{ profile.role }</p>

                    </div>
                );
            }) }

        </WrapperStyled>
    );
};

export default AdminPage;

const toStringUtil = (item) => {
    let result = "";
    if (item) {
        result = item.toString();
    }else {
        result = "false";
    }
    return result;
};
