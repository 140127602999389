import React, { useState } from "react";

const DisplayImage = ({photo_url, orient}) => {
    let defaultImage = "https://i.imgur.com/J478ziE.jpg";
    if (orient === "man") {
        defaultImage = "https://i.imgur.com/J478ziE.jpg";
    }


    const [photo, setPhoto] = useState(photo_url || defaultImage);

    return (
        <img src={ photo } alt=""

             onError={ (e) => {
                 setPhoto(defaultImage);
             } }
        />
    );
};

export default DisplayImage;
