import React from "react";
import { EuiCallOut, EuiButton } from "@elastic/eui";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const WrapperStyled = styled.div`
  width: 600px;
  margin: 0 auto;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ProfileMessages = () => {
    const navigate = useNavigate();
    const gotoProfile = () => {
        navigate("/profile", { replace: true });
    };

    let mes = "To search for profiles, please complete your own profile first";


    return (
        <WrapperStyled>
            <EuiCallOut title="Warning" color="warning" iconType="help">
                <h4>{ mes }</h4>
                <h5>Required profile settings are:</h5>
                <p>Display name</p>
                <p>Username</p>
                <p>Display my self in search results must be turned ON</p>
                <EuiButton onClick={ gotoProfile } color="warning">
                    Edit Profile
                </EuiButton>
            </EuiCallOut>
        </WrapperStyled>
    );
};

export default ProfileMessages;
