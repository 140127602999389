import React, { useContext, useState } from "react";
import { EuiRadio, EuiCheckbox, EuiDualRange, EuiButton, EuiIcon } from "@elastic/eui";
import styled from "styled-components";
import { isUserCompletedProfile } from "../utils";
import { MainContext } from "../context/MainContext";
import fetchService from "../utils/fetch.service";
import { Link } from "react-router-dom";


export const FormStyled = styled.div`
  width: 100%;
  margin-top: 70px;

  @media (max-width: 800px) {
    padding: 0 2%;
  }

  .wrapper {
    @media (max-width: 800px) {
      text-align: center;
    }
  }
`;

export const FormItemStyled = styled.div`
  width: 600px;
  margin: 0 auto 50px;

  @media (max-width: 800px) {
    width: 100%;
    margin: 70px auto;
  }

  .euiRadio__label {
    font-size: 21px !important;
  }

  .gulchinara {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 20px;
    margin-left: 200px;
    margin-bottom: 10px;

    @media (max-width: 800px) {
      grid-template-columns: 100px 70px;
      margin: 10px auto;
      width: 220px;
    }
  }

  &.orientation {
    .euiFormControlLayout__childrenWrapper {
      margin-left: 210px;

      @media (max-width: 800px) {
        margin: 0;
      }
    }
  }

  .juiawdw {
    .euiCallOut {
      margin: 16px 0;
    }
  }

  &.verified_iidoo3 {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px;
    align-items: baseline;
  }
`;

export const FormItemHeadingStyled = styled.div`
  font-size: 42px;
  margin: 0 0 20px 0;
`;

const SearchPanel = () => {
    const { userContext, profilesContext, searchedContext, isSearchingContext } = useContext(MainContext);
    const [user] = userContext;
    const [, setProfiles] = profilesContext;
    const [, setSearched] = searchedContext;
    const [, setIsSearching] = isSearchingContext;

    const lookForLocal = localStorage.getItem("lookFor");
    const agesLocal = localStorage.getItem("ages");

    const [lookFor, setLookFor] = useState(lookForLocal || "woman");
    const [ages, setAges] = useState(agesLocal ? JSON.parse(agesLocal) : [22, 50]);
    const [verified, setVerified] = useState(false);

    const [searching, setSearching] = useState(false);

    const isUserVerified = !!user.po;

    const searchForBitches = async () => {
        if (searching) return;
        setSearching(true);
        setIsSearching(true);
        const response = await fetchService.post("/user-s", { lookFor: lookFor, ages: ages, verified });
        if (response.data) {
            setProfiles(response.data);
            // setProfiles([]);
        }
        setSearching(false);
        setSearched(true);
        setIsSearching(false);
    };

    return (
        <FormStyled>
            <div className="wrapper">
                <FormItemStyled>
                    <FormItemHeadingStyled>Looking for</FormItemHeadingStyled>
                    <div className="gulchinara">
                        <EuiRadio
                            label="Woman"
                            checked={ lookFor === "woman" }
                            size="l"
                            onClick={ () => {
                                localStorage.setItem("lookFor", "woman");
                                setLookFor("woman");
                            } }
                            onChange={ () => {
                            } }
                        />
                        <EuiRadio
                            label="Man"
                            checked={ lookFor === "man" }
                            onClick={ () => {
                                localStorage.setItem("lookFor", "man");
                                setLookFor("man");
                            } }
                            onChange={ () => {
                            } }
                        />
                    </div>
                </FormItemStyled>

                {/*<FormItemStyled className="orientation">*/ }
                {/*    <FormItemHeadingStyled>Orientation</FormItemHeadingStyled>*/ }
                {/*    <EuiSelect*/ }
                {/*        options={ SEX_OPTIONS }*/ }
                {/*        value={ "bisexual" }*/ }
                {/*        onChange={ () => {*/ }
                {/*        } }*/ }
                {/*    />*/ }
                {/*</FormItemStyled>*/ }

                <FormItemStyled className="age">
                    <FormItemHeadingStyled>Age</FormItemHeadingStyled>
                    {/*<EuiSelect*/ }
                    {/*    options={ options }*/ }
                    {/*    value={ 22 }*/ }
                    {/*    onChange={ () => {*/ }
                    {/*    } }*/ }
                    {/*/>*/ }
                    {/*<EuiSelect*/ }

                    {/*    options={ options }*/ }
                    {/*    value={ 78 }*/ }
                    {/*    onChange={ () => {*/ }
                    {/*    } }*/ }
                    {/*/>*/ }
                    <EuiDualRange
                        min={ 18 }
                        max={ 100 }
                        step={ 1 }
                        value={ ages }
                        onChange={ (value) => {
                            localStorage.setItem("ages", JSON.stringify(value));
                            setAges(value);
                        } }
                        isDraggable
                        showLabels
                        showRange
                        showInput
                        fullWidth
                    />
                </FormItemStyled>
                <FormItemStyled className="verified_iidoo3">
                    <EuiCheckbox
                        id={ "checkbox" }
                        disabled={ !isUserVerified }
                        label="Verified only"
                        checked={ verified }
                        onChange={ () => {
                            setVerified(!verified);
                        } }
                    />

                    { !isUserVerified && (
                        <div className="juiawdw_lopps">
                            <EuiIcon type="sortLeft"/> To use this feature you need to <Link
                            to={ "/verify" }>verify</Link> your gender!
                        </div>
                    ) }
                </FormItemStyled>
                <FormItemStyled>
                    <EuiButton
                        color={ "success" }
                        isDisabled={ !isUserCompletedProfile(user) }
                        fill
                        onClick={ searchForBitches }
                    >
                        Search
                    </EuiButton>
                </FormItemStyled>
            </div>
        </FormStyled>
    );
};

export default SearchPanel;


function generateOptions() {
    const options = [];
    for (let i = 18; i <= 90; i++) {
        options.push({
            value: i,
            text: i,
        });
    }
    return options;
}

export const SEX_OPTIONS = [
    {
        value: "straight",
        text: "Straight",
        klo: ["man", "woman"]
    },
    {
        value: "gay",
        text: "Gay",
        klo: ["man"]
    },
    {
        value: "lesbian",
        text: "Lesbian",
        klo: ["woman"]
    },
    // {
    //     value: "bisexual",
    //     text: "Bisexual",
    //     klo: ["man", "woman"]
    // }
];
