import React from "react";
import styled from "styled-components";
import { EuiCallOut, EuiToken } from "@elastic/eui";
import GoBackToButton from "../components/GoBackTo";
import DATA from "../const/data";

const WrapperStyled = styled.div`
  width: 600px;
  margin: 10px auto 50px;

  @media (max-width: 800px) {
    width: 100%;
    margin: 10px 0;
    padding: 0 2%;
  }

  h1 {
    font-size: 42px;
    margin: 70px 0 30px;
  }

  h2 {
    font-size: 32px;
    margin: 30px 0 10px;
  }

  p {
    font-size: 18px;
  }

  .lokj {
    background: rgb(29, 30, 36);
    font-size: 20px;
    font-weight: 700;
    padding: 10px 20px;
  }

  .euiPanel {
    margin-top: 20px;
  }
`;

// snowflake, check, checkInCircleFilled

// tokenVariable

const VerifyPage = () => {
    document.title = `Verify${ DATA.websiteTitle }`;
    return (
        <WrapperStyled>
            <GoBackToButton backTo={ "/" } goToText={ "Main page" }/>
            <h1>Verify your gender (WOMEN only)</h1>

            <p className="lokj">Your name<EuiToken iconType="tokenVariable"/></p>

            <h2>Lesbian</h2>
            <p>As a lesbian you want to be sure you contact a woman, in order to be able to look for lesbians you have
                to verify your gender first</p>

            <h2>Straight</h2>
            <p>Your verified badge will attract more contacts</p>

            <h2>How to</h2>
            <p>Make a SELFIE with your telegram username on PAPER where we can clearly verify your gender,
                send the picture to <a href="https://t.me/achi_tov_levad" target="_blank">@achi_tov_levad</a></p>
            <EuiCallOut color="warning" size="m" title="This verification process is only for WOMEN" iconType="help"/>
        </WrapperStyled>
    );
};

export default VerifyPage;
