import styled from "styled-components";

export const Container = styled.main`
  width: 1200px;
  margin: 20px auto;
  padding-bottom: 20px;

  @media (max-width: 800px) {
    width: 100%;
  }
  
  
  .link-toad{
    text-align: center;
  }
`;

export const AuthContainer = styled.main`
  width: 800px;
  margin: 20px auto;

  @media (max-width: 800px) {
    width: 100%;
  }
`;
