import Lottie from "lottie-react";
import React from "react";

const LottieAnimation = ({ lottie }) => {
    return (
        <Lottie
            loop={ true }
            animationData={ lottie }
        />
    );
};

export default LottieAnimation;
