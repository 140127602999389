import React, { useContext } from "react";
import styled from "styled-components";
import { MainContext } from "../context/MainContext";
import DisplayImage from "./DisplayImageCustom";
import Loader from "./Loader";
import { EuiToken } from "@elastic/eui";

const WrapperStyled = styled.div`
  width: 600px;
  margin: 0 auto 50px;

  @media (max-width: 800px) {
    width: 100%;
    margin: 0 auto;
  }
`;

const NoStyled = styled.div`
  font-size: 30px;
  text-align: center;
`;

const ResultsStyled = styled.div`
  h2 {
    text-align: center;
    font-size: 30px;
    margin-bottom: 15px;
  }
`;

const ItemsStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    padding: 2%;
  }
`;

const ItemStyled = styled.div`
  border-radius: 6px;
  //padding: 16px;
  background-color: rgb(29, 30, 36);
  box-shadow: rgb(0 0 0 / 28%) 0px 0.9px 4px, rgb(0 0 0 / 21%) 0px 2.6px 8px, rgb(0 0 0 / 18%) 0px 5.7px 12px, rgb(0 0 0 / 14%) 0px 15px 15px;


  img {
    width: 100%;
  }

  h4 {
    padding-left: 10px;
    font-size: 21px;
    margin-bottom: 5px;
  }

  a {
    text-align: right;
    font-size: 25px;
    margin-top: 15px;
    display: block;
  }

  .wpar_ojkiu {
    padding: 10px;

    .opdz {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: baseline;
      grid-gap: 1px;
    }

    .kkz {
      color: rgb(129, 133, 143);
      font-size: 15px;
    }

    .kk12z {
      //font-size: 21px;
    }
  }
`;


const Results = () => {
    const { profilesContext, userContext, isSearchingContext } = useContext(MainContext);
    const [profiles] = profilesContext;
    const [user] = userContext;
    const [isSearching] = isSearchingContext;

    const profilesD = profiles;

    const resultsAreEmpty = profiles.length === 0;

    return (
        <WrapperStyled>
            { resultsAreEmpty && (
                <NoStyled>Looks like it's empty, give it some time</NoStyled>
            ) }
            { !resultsAreEmpty && (
                <ResultsStyled>
                    <h2>Results</h2>
                    { isSearching && (
                        <Loader/>
                    ) }
                    <ItemsStyled>
                        { profilesD.map((profile) => (
                            <ItemStyled key={ profile._id }>
                                <div className="img">
                                    <DisplayImage photo_url={ profile.photo_url } orient={ user.lookFor }/>
                                </div>

                                <div className="wpar_ojkiu">
                                    <div className="opdz">
                                        <div className="kkz">
                                            Name:
                                        </div>
                                        <div className="kk12z">
                                            <h4>{ profile.displayName || "No name" }{ profile.po && (
                                                <EuiToken iconType="tokenVariable"/>) }</h4>
                                        </div>
                                    </div>
                                    <div className="opdz">
                                        <div className="kkz">
                                            Age:
                                        </div>
                                        <div className="kk12z">
                                            { profile.age }
                                        </div>
                                    </div>
                                    <div className="opdz">
                                        <div className="kkz">
                                            Gender:
                                        </div>
                                        <div className="kk12z">
                                            { profile.gender }
                                        </div>
                                    </div>


                                    <a href={ `https://t.me/${ profile.username }` } target="_blank">Contact</a>
                                </div>
                            </ItemStyled>
                        )) }
                    </ItemsStyled>
                </ResultsStyled>
            ) }

        </WrapperStyled>
    );
};

export default Results;
