import React from "react";
import styled from "styled-components";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const ButtonStyled = styled.div`
  text-align: right;
  
  .as-link {
    color: #36a2ef;
    cursor: pointer;
  }
`;

const LogOut = () => {
    return (
        <ButtonStyled>
            <div className="as-link" onClick={ () => {
                cookies.remove("tpir", { path: "/" });
                window.location.reload();
            } }>Logout
            </div>
        </ButtonStyled>
    );
};

export default LogOut;
