import React from "react";
import { useNavigate } from "react-router-dom";
import { EuiButton } from "@elastic/eui";
import styled from "styled-components";

const WrapperStyled = styled.div`
  position: relative;
`;

const GoBackToButton = ({ backTo, goToText }) => {
    const navigate = useNavigate();

    const goto = () => {
        navigate(backTo, { replace: true });
    };

    return (
        <WrapperStyled>
            <EuiButton
                onClick={ goto }
                color="primary"
                iconType="arrowLeft"
            >
                Go back to { goToText }
            </EuiButton>
        </WrapperStyled>
    );
};

export default GoBackToButton;
