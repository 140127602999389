import React, { useContext } from "react";
import { MainContext } from "../context/MainContext";
import {
    useLocation,
    Navigate,
} from "react-router-dom";

const AuthMiddleware = ({ children }) => {
    const location = useLocation();
    const { userContext, isLoggedContext } = useContext(MainContext);
    const [user, setUser] = userContext;
    const [isLogged, setIsLogged] = isLoggedContext;

    if (!isLogged) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={ { from: location } } replace/>;
    }

    return (children);
};

export default AuthMiddleware;
