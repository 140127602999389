import React, { useContext } from "react";
import ProfileMessages from "../components/ProfileMessages";
import SearchPanel from "../components/SearchPanel";
import Results from "../components/Results";
import { isUserCompletedProfile } from "../utils";
import { MainContext } from "../context/MainContext";
import { EuiButton } from "@elastic/eui";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import DATA from "../const/data";
import LogOut from "../components/Logout";

const ProfileButtonStyled = styled.div`
  width: 600px;
  margin: 10px auto 50px;
  text-align: right;

  @media (max-width: 800px) {
    width: 100%;
    margin: 10px 0;
    padding-right: 2%;
  }

`;

const HeaderSectionStyled = styled.div`
  width: 600px;
  margin: 10px auto 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  @media (max-width: 800px) {
    width: 100%;
    margin: 10px 0;
    padding-right: 2%;
    grid-template-columns: 24% 24% 14% 21% 13%;
  }
  
  .ju{
    color: #f68fbe;
  }
  
  .bl{
    color: #6dccb1;
  }
  
  .sex{
    color: #ffc107;
  }
  
  .jpp{
    color: #d33b3b;
  }
  
  a{
    font-weight: bold;

    @media (max-width: 800px) {
     font-size: 12px;
    }
  }
`;

const MainPage = () => {
    document.title = `Search${ DATA.websiteTitle }`;
    const navigate = useNavigate();
    const { userContext, searchedContext } = useContext(MainContext);
    const [user] = userContext;
    const [searched] = searchedContext;

    const gotoProfile = () => {
        navigate("/profile", { replace: true });
    };

    const isUserAdmin = user.role === "admin" || user.role === "moderator";


    return (
        <section>
            <HeaderSectionStyled>
                <a className='jpp' href="https://telegramadult.com/" target="_blank" rel="noreferrer">Adult Telegram</a>
                <a className='ju' href="https://juicyelf.com/" target="_blank" rel="noreferrer">Best PORN site</a>
                <a className="bl" href="https://noshitblog.com/" target="_blank" rel="noreferrer">Our blog</a>
                <a className="sex" href="https://textmesex.com/" target="_blank" rel="noreferrer">Sex stories</a>
                <LogOut/>
            </HeaderSectionStyled>
            { isUserAdmin && (
                <div className="link-toad">
                    <Link to={ "/admin" }>Admin</Link>
                </div>
            ) }
            { !isUserCompletedProfile(user) && (
                <ProfileMessages/>
            ) }
            <ProfileButtonStyled>
                <EuiButton onClick={ gotoProfile } color="success">
                    Edit your Profile
                </EuiButton>
            </ProfileButtonStyled>
            <SearchPanel/>
            { searched && (
                <Results/>
            ) }
        </section>
    );
};

export default MainPage;
