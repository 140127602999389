import React, { useCallback, useContext } from "react";
import AuthLayout from "../layouts/Auth.layout";
import { MainContext } from "../context/MainContext";
import styled from "styled-components";
import LottieAnimation from "../components/LottieAnimation";
import { Animation } from "../const/animation";
import { getRandomValueFromArray } from "../utils";
import fetchService from "../utils/fetch.service";
import Cookies from "universal-cookie";
import TelegramLoginButton from "../components/TelegramAuth";
import DATA from "../const/data";
import { EuiCallOut } from "@elastic/eui";

const cookies = new Cookies();

const AnimationStyled = styled.div`
  width: 500px;
  margin: auto;

  @media (max-width: 800px) {
    width: 100%;
  }

  h1 {
    color: #F68FBE;
    font-size: 50px;
    text-align: center;
    margin-top: 10px;;
  }

  .login-butto {
    text-align: center;
    margin: 30px;
    padding-bottom: 30px;
  }
`;

const AuthPage = () => {
    document.title = `Login${ DATA.websiteTitle }`;
    const { userContext, isLoggedContext } = useContext(MainContext);
    const [, setUser] = userContext;
    const [, setIsLogged] = isLoggedContext;

    const lottieAnimationKey = getRandomValueFromArray(Object.keys(Animation));

    const onTelegramAuth = useCallback(async (user) => {
        fetchService.post("/auth", user).then(res => {
            cookies.set("tpir", res.data.token, { path: "/", expires: new Date(Date.now() + 25920000000) });
            if (res.data.user) {
                setUser(res.data.user);
                setIsLogged(true);
            }
        }).catch(err => {
            console.log(err);
        });
    }, []);

    return (
        <AuthLayout>
            <AnimationStyled>
                <EuiCallOut title="Thanks for trying out our new app" color="success" iconType="user">
                    <p>This app is in BETA, it might not work perfectly or have less matches than you expect</p>
                    <p>We work hard to fix the bugs and make this app great for you guys</p>
                </EuiCallOut>

                <h1>Sex Chat Dating</h1>

                <div className="login-butto">
                    <TelegramLoginButton dataOnauth={ onTelegramAuth } botName="tele_sex_chat_bot"/>
                </div>
                <LottieAnimation lottie={ Animation[lottieAnimationKey] }/>
            </AnimationStyled>
        </AuthLayout>
    );
};

export default AuthPage;
