export const getRandomValueFromArray = (array) => {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
};

export const isUserCompletedProfile = (user) => {
    if(!user) return false;
    if (!user.displayName) return false;
    if (!user.username) return false;
    if (!user.gender) return false;
    if (!user.orientation) return false;
    if (!user.age) return false;
    if (!user.displayOnSearch) return false;
    return true;
};
