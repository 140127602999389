import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import { Container } from "./MainLayoutStyled";
import { MainContext } from "../context/MainContext";

const MainLayout = () => {
    const { userContext } = useContext(MainContext);
    const [user] = userContext;

    if (!user) return (<div>Loading...</div>);

    return (
        <Container>
            <Outlet/>
        </Container>
    );
};

export default MainLayout;
