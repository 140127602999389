import Cookies from "universal-cookie";
import appSettings from "../const/appSettings";

const cookies = new Cookies();

class FetchService {
    get = async (url, jtw) => {
        return new Promise((resolve, reject) => {
            const request = new Request(appSettings.apiUrl + url, {
                method: "GET",
                headers: this.#getHeaders(jtw)
            });

            return fetch(request)
                .then((response) => {
                        resolve(response.json());
                    }
                );
        });
    };

    post = async (url, body, jtw) => {
        return new Promise((resolve, reject) => {
            const request = new Request(appSettings.apiUrl + url, {
                method: "POST",
                headers: this.#getHeaders(jtw),
                body: JSON.stringify(body)
            });

            return fetch(request)
                .then((response) => {
                        if (response) {
                            resolve(response.json());
                        } else {
                            resolve([]);
                        }
                    }
                ).catch(e => {
                    console.log("w", e);
                    resolve([]);
                });
        });
    };

    put = async (url, body, jtw) => {
        return new Promise((resolve, reject) => {
            const request = new Request(appSettings.apiUrl + url, {
                method: "PUT",
                headers: this.#getHeaders(jtw),
                body: JSON.stringify(body)
            });

            return fetch(request)
                .then((response) => {
                        if (response) {
                            resolve(response.json());
                        } else {
                            resolve([]);
                        }
                    }
                ).catch(e => {
                    console.log("w", e);
                    resolve([]);
                });
        });
    };

    #getHeaders = (jwt) => {
        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };

        if (jwt) {
            headers["AUTHORIZATION"] = `Bearer ${ jwt }`;
        }

        if (!jwt) {
            const kk = cookies.get("tpir");
            if (kk) {
                headers["AUTHORIZATION"] = `Bearer ${ kk }`;
            }
        }
        // headers['AUTHORIZATION'] = `Bearer eyJhbGciOiJIUzI1NiJ9.MzQ0MzY1.1KlKmQhhsy4UWiL_hjpoo9HO4OMhkYkPKfrFa3HHuRI`;
        return headers;
    };
}

const fetchService = new FetchService();
export default fetchService;
