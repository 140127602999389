import { createContext, useState } from 'react';

export const MainContext = createContext(null);

export const MainContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [profiles, setProfiles] = useState([]);
    const [searched, setSearched] = useState(false);
    const [isLogged, setIsLogged] = useState(false);
    const [isSearching, setIsSearching] = useState(false);

    return (
        <MainContext.Provider value={ {
            userContext: [user, setUser],
            isLoggedContext: [isLogged, setIsLogged],
            profilesContext: [profiles, setProfiles],
            searchedContext: [searched, setSearched],
            isSearchingContext: [isSearching, setIsSearching]
        } }>
            { children }
        </MainContext.Provider>
    );
};
